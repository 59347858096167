export const LOADING = "LOADING";
export const REGISTER_API_ERR = "REGISTER_API_ERR";
export const REGISTER_API = "REGISTER_API";
export const SIGN_IN_API_ERR = "SIGN_IN_API_ERR";
export const SIGN_IN_API = "SIGN_IN_API";
export const POST_ONBOARDING_STORE_ERR = "POST_ONBOARDING_STORE_ERR";
export const POST_ONBOARDING_STORE = "POST_ONBOARDING_STORE";
export const POST_SHAREHOLDER_STORE_ERR = "POST_SHAREHOLDER_STORE_ERR";
export const POST_SHAREHOLDER_STORE = "POST_SHAREHOLDER_STORE";
export const UPLOAD_DOCUMENT_ERR = "UPLOAD_DOCUMENT_ERR";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const COMPANY_DETAIL = "COMPANY_DETAIL";
export const COMPANY_DETAIL_ERR = "COMPANY_DETAIL_ERR";
export const CERTICATE_DETAIL_ERR = "CERTICATE_DETAIL_ERR";
export const CERTICATE_DETAIL = "CERTICATE_DETAIL";
export const DASHBOARD_DETAIL = "DASHBOARD_DETAIL";
export const DASHBOARD_DETAIL_ERR = "DASHBOARD_DETAIL_ERR";
export const DASHBOARD_COMPANIES_DETAIL = "DASHBOARD_COMPANIES_DETAIL";
export const DASHBOARD_COMPANIES_DETAIL_ERR = "DASHBOARD_COMPANIES_DETAIL_ERR";
export const DASHBOARD_COMPANY_DOC = "DASHBOARD_COMPANY_DOC";
export const DASHBOARD_COMPANY_DOC_ERR = "DASHBOARD_COMPANY_DOC_ERR";
export const FORGET_PASSWORD_SEND_ERR = "FORGET_PASSWORD_SEND_ERR";
export const FORGET_PASSWORD_SEND = "FORGET_PASSWORD_SEND";
export const OTP_VERIFICATION_ERR = "OTP_VERIFICATION_ERR";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const RESET_PASSWORD_ERR = "RESET_PASSWORD_ERR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFICATION_DOC_ERR = "VERIFICATION_DOC_ERR";
export const VERIFICATION_DOC = "VERIFICATION_DOC";

export const PERSONAL_PROFILE = "PERSONAL_PROFILE";
export const PERSONAL_PROFILE_ERR = "PERSONAL_PROFILE_ERR";
export const SUPPORTING_DOC = "SUPPORTING_DOC";
export const SUPPORTING_DOC_ERR = "SUPPORTING_DOC_ERR";
export const DEGREE_CERTIFICATE = "DEGREE_CERTIFICATE";
export const DEGREE_CERTIFICATE_ERR = "DEGREE_CERTIFICATE_ERR";
export const SMART_CONTRACT = "SMART_CONTRACT";
export const SMART_CONTRACT_ERR = "SMART_CONTRACT_ERR";
export const PERSONAL_DOC_ERR = "PERSONAL_DOC_ERR";
export const PERSONAL_DOC = "PERSONAL_DOC";
export const COMPANY_DOC_ERR = "COMPANY_DOC_ERR";
export const COMPANY_DOC = "COMPANY_DOC";
export const RE_UPLOAD_ERR = "RE_UPLOAD_ERR";
export const RE_UPLOAD = "RE_UPLOAD";
