import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserSignBar from "../../../Component/UserSignBar";
// import { getCertificate,verifyDocument } from '../../../../store/User/action';
import { toast } from "react-toastify";
// import load from '../../../Assets/load'
import QRCode from "qrcode.react";
import qrImage from "../../../Assets/Blockhain_reg_new.png";

class ShareDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: false,
      certificate: undefined,
      isVerified: false,
      isMismatched: false,
      sendingVerification: false,
      qrCodeValue: "",
    };
  }
  componentDidMount() {
    console.log("url", this.props?.history?.location?.state);
    this.setState({});
  }

  componentDidUpdate(prev) {
    if (prev.certificateDetail !== this.props.certificateDetail) {
      console.log(this.props.certificateDetail);
      this.setState({
        certificate: this.props?.certificateDetail?.data?.data?.file,
      });
    }

    if (prev.verify_doc_succ !== this.props.verify_doc_succ) {
      toast.success(this.props.verify_doc_succ?.data?.message);
      this.setState({
        isVerified: true,
        sendingVerification: false,
      });
      //    this.props.history.push('/Company_Detail');
    }

    if (prev.verify_doc_fail !== this.props.verify_doc_fail) {
      console.log(this.props.verify_doc_fail);
      toast.error(
        this.props.verify_doc_fail?.response?.data?.message ||
          this.props.verify_doc_fail?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingVerification: false,
      });
    }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handleGenerate = () => {
    const qrCodeValue = this.props.location.state.url;
    this.setState({ qrCodeValue });
  };

  handleCopyUrl = () => {
    if (!navigator.clipboard) {
      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = this.props.location.state.url;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();
      textarea.setSelectionRange(0, this.props.location.state.url.length);

      try {
        // Execute the copy command
        const success = document.execCommand("copy");
        if (!success) {
          throw new Error("Copy command was unsuccessful");
        }
        toast.success("Url Copied");
      } catch (err) {
        console.error("Unable to copy URL: ", err);
        alert("Copying to clipboard failed.");
      } finally {
        // Remove the textarea from the DOM
        document.body.removeChild(textarea);
      }
      return;
    }

    navigator.clipboard.writeText(this.props.location.state.url);
    toast.success("Url Copied ");
  };

  render() {
    console.log(this.props.location.state.url, "-----------------");
    return (
      <div className="listed-page">
        <div className="lg-page-banner">
          <UserSignBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="pageData">
            <div className="first-row">
              {/* <img src={logo}/> */}
              <p>
                {"Sharing the Certificate"} <br />
              </p>
            </div>
            <div className=" d-flex flex-column justify-content-center align-items-center border w-50 m-auto p-3">
              <div className="">
                {this.state.qrCodeValue && (
                  <QRCode
                    value={this.state.qrCodeValue}
                    renderAs="canvas"
                    imageSettings={{
                      src: qrImage,
                      excavate: true,
                      height: 28,
                      width: 28,
                    }}
                  />
                )}
              </div>
              <div className="">
                <button
                  onClick={this.handleGenerate}
                  className="generic-button"
                >
                  Generate a QR now
                </button>
              </div>
              <div>OR</div>
              <div>
                <button onClick={this.handleCopyUrl} className="generic-button">
                  Copy URL Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    // certificateDetail: state.admin.getCertificate,
    // certificateDetail_err:state.admin.getCertificate_err,
    // verify_doc_succ:state.admin.verify_doc,
    // verify_doc_fail:state.admin.verify_doc_err
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // getCertificate,
      // verifyDocument
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareDocument);
