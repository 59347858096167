import React from "react";
import loader from "../Assets/loaderVideo.mp4";
const VideoLoader = (props) => {
  return (
    <div
      className="video-loader-container"
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <video
        autoPlay
        muted
        playsInline
        className="video-loader"
        style={{ height: "100%", width: "100%" }}
        onEnded={props.handleVideoEnd}
      >
        <source src={loader} type="video/mp4" width={"100%"} height={"100%"} />
      </video>
    </div>
  );
};

export default VideoLoader;
