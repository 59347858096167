import React from 'react';
// import blockChain from '../Assets/blockchainImg.png'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import { postSignInApi } from '../store/User/action';
import { FcGoogle } from 'react-icons/fc';

import bgImage from '../Assets/shared image.jpg'
import dubailogo from '../Assets/Blockhain_reg_new.png'

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            search: '' ,
            password:'',
            username:'',
            errors:'',
            signIn:true

        };
    }

    componentDidMount(){
        localStorage.clear()
    }

    handleRedirect=()=>{
        this.props.history.push("/SignUp");
    }
    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }

    handleSignIn=()=>{
        const body={
            username:this.state.username,
            password:this.state.password
        }

        if(this.state.username===''|| this.state.password===''){
            toast.error('Username/Password cannot be empty');
            return;
        }
        this.props.postSignInApi(body)
    }

    handleForgetPassword=()=>{
        this.props.history.push("/forget_password");
    }

    componentDidUpdate(prev){
        // console.log(this.props.succ_reg.data.data,"updates");
        if(prev.succ_reg !== this.props.succ_reg){
            console.log(this.props?.succ_reg?.data?.data);
            toast.success(this.props.succ_reg.data.message || this.props.succ_reg.data.Error);
            localStorage.setItem('username',this.props?.succ_reg?.data?.data?.username);
            localStorage.setItem('userId', this.props?.succ_reg?.data?.data?._id);
            localStorage.setItem('email',this.props?.succ_reg?.data?.data?.email);
            localStorage.setItem('coded_password',this.props?.succ_reg?.data?.data?.coded_password);
            localStorage.setItem('access_token',this.props?.succ_reg?.data?.access_token);
            localStorage.setItem('refresh_token',this.props?.succ_reg?.data?.refresh_token);

            if(this.props?.succ_reg?.data?.company_data){
                localStorage.setItem('companyId',this.props?.succ_reg?.data?.company_data?._id);
            }
            if(this.props?.succ_reg?.data?.individual_data){
                localStorage.setItem('individualId',this.props?.succ_reg?.data?.individual_data?._id);
            }
           
            this.props.history.push('/user/Dashboard')
        }
        if(prev.fail_reg !== this.props.fail_reg){
            toast.error(this.props?.fail_reg?.response?.data?.message||'Sign in failed');
        }
    }

    render(){
        return(
            <div className='login-page'>
                <ToastContainer />
                {/* <div className='lg-page-banner'>
                   <div className='banner'>
                   <label style={{color:'white'}}>Decentralized and Uncompromised trust <br/> BlockChain Registry</label>
                        <p style={{color:'white'}}>Kindly complete the Onboarding Process</p>
                        <div className='banner-img'>
                        </div>
                   </div>
                </div> */}
                {/* <div className='lg-page-form'>
                    <div className='lg-pg-form-box'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='form-title'>Sign In</label><br/>
                                <label className='form-subtitle'>Kindly Sign in using your credentials</label>
                            </div>
                        </div>
                        <div className="row form-field">
                            <div className='form-input'>
                                <label>Enter your Username or Email ID</label>
                                <input type="text" name="username" value={this.state.username} placeholder="Username or E-Mail ID" onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                <label>Enter your Password</label>
                                <input type="password" name="password" value={this.state.password} placeholder="Enter your password" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className='row form-submit' onClick={()=>this.handleForgetPassword()}>
                            <a>Forgot password ?</a>
                        </div>
                        <div className='row form-submit1'>
                            <button onClick={() => this.handleSignIn()}>Sign In</button>
                        </div>
                        <div className='row create_accnt'>
                            <center>
                                <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up</span></a>
                                <br/>
                                <button><FcGoogle /> Sign In using Google Account</button>
                            </center>
                        </div>
                    </div>
                </div> */}

<div className='new-lg-page-form'>
                    <div className='bg-image'>
                        <img src={bgImage}/>
                    </div>
                    <div className='top-head'>
                        <div style={{fontSize:'30px',fontWeight:'bold'}}>Decentralized and uncompromised trust - Blockchain Registry </div> 
                        <div style={{fontSize:'18px'}}>Complete the Onboarding Process.</div>
                    </div>

                    <div className='lg-pg-form-box'>
                        <img src={dubailogo} style={{width:'20%'}}/>
                       
                        <div className="row form-field">
                            <div className='form-input'>
                                {/* <label>Enter your Username or Email ID</label> */}
                                <input type="text" name="username" value={this.state.username} placeholder="Username or E-Mail ID" onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                {/* <label>Enter your Password</label> */}
                                <input type="password" name="password" value={this.state.password} placeholder="Enter your password" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className='row form-submit'  onClick={()=>this.handleForgetPassword()}>
                            <a>Forgot password ?</a>
                        </div>
                        <div className='row form-submit1'>
                            <button onClick={() => this.handleSignIn()}>Sign In</button>
                        </div>
                        <div className='row create_accnt'>
                            <center>
                                <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up</span></a>
                                <br/>
                                {/* <button><FcGoogle /> Sign In using Google Account</button> */}
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        succ_reg: state.user.sign_in_succ,
        fail_reg: state.user.sign_in_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        postSignInApi,
      },
      dispatch,
    )
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Login)

