import React, { Component } from 'react';
import { BiSearch , BiBell } from "react-icons/bi";
import {FiMenu, FiSettings} from 'react-icons/fi';
import {BsSearch} from 'react-icons/bs';
import logo from '../../Assets/Blockhain_reg_new.png'
import user from '../../Assets/Icon metro-user.svg'
import { RiArrowDropDownLine } from "react-icons/ri";
import Loader from '../../Component/Loader'
class Header1 extends Component {

    constructor(){
        super();
        this.state={
            notification_count:0,
            open:false,
            loading:true
        }
    }

    componentDidMount=()=>{
    //    this.ringNotification();
    }
    
    handledown=()=>{
        this.setState({
            open: !this.state.open
        })
    }
    
    handleLogout=()=>{
        localStorage.clear();
        this.setState({
            open: false
        })
        this.props.history.push('/')
    }

    handleLogo=()=>{
        this.props.history.push('/Dashboard')
    }

    handleVideoEnd=()=>{
        this.setState({
            loading:false
        })
    }

    
  
    render(){
        return (this.state.loading?<Loader handleVideoEnd={this.handleVideoEnd}/>: 
        (<div className='general-layout'>
                    <div className='gn-layout-header'>
                        <div className='gn-ly-header-left'>
                            <img src={logo} id="big-logo" className='p-1' onClick={this.handleLogo} style={{cursor:'pointer', width:'6.2rem'}}/>
                        </div>
                        <div className='gn-ly-header-right'>
                            <div className='input-box'>
                                {/* <a><img src={user} /></a>
                                <p>Rakesh Sharma <br/> <span>AYE Fintech , Dubai.</span></p> */}
                            </div>
                            <div className="dropdown show">
                                <div className='bell-box' >
                                </div>
                            </div>
                            <div className='settings-box'>
                                {/* <FiSettings id="icon_setting"></FiSettings> */}
                            </div>
                            <div className='settings-box1'>
                                {/* <RiArrowDropDownLine id="icon_drop" onClick={this.handledown}/> */}
                            </div>
                            <div className='mobile-menu'>
                                <FiMenu></FiMenu>
                            </div>
                        </div>
                        {this.state.open == true &&
                            <ul style={{background:'#000000',marginLeft:'83%',marginTop:'6%',padding:'10px 20px',zIndex:'1'}}>
                                <li style={{color:'lightgrey',listStyle:'none',fontWeight:'bold',cursor:'pointer'}} onClick={this.handleLogout}>Logout</li>
                            </ul>
                        }
                        
                    </div>
                    <div className='gn-middle-header'>
                        
                    </div>
                    <div className='gn-layout-body'>
                        {this.props.children}
                        
                    </div>
        </div>))
    }
}

export default Header1;