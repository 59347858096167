import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../store/User/action';
import { ToastContainer, toast } from 'react-toastify';


import bgImage from '../Assets/shared image.jpg'
import dubailogo from '../Assets/Blockhain_reg_new.png'
class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state={
           password:'',
           confirmPassword:'',
        }
    }

    componentDidMount(){
        console.log(this.props.location.state.userId)
    }

    componentDidUpdate(prev){
        if(prev.succ_reset!==this.props.succ_reset){
            toast.success(this.props.succ_reset.data.message||this.props.succ_reset.data.Error||"Reset Successful");
            console.log(this.props.succ_reset);
            this.props.history.push('/');
        }

        if(prev.fail_reset!==this.props.fail_reset){
            toast.error(this.props.fail_reset.response.data.message||"Reset Failed");
        }

    }

    handleReset=()=>{
        if(this.state.password!==this.state.confirmPassword){
            toast.error('Password does not match');
            return;
        }
        this.props.resetPassword({password:{newPassword:this.state.password}, userId:this.props.location.state.userId})
    }

    handleRedirect=()=>{
        this.props.history.push('/SignUp')
    }

    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})     
    }

  render() {
    return (
        <div className='login-page'>
        <ToastContainer />
        {/* <div className='lg-page-banner'>
           <div className='banner'>
           <label style={{color:'white'}}>Decentralized and Uncompromised trust <br/> BlockChain Registry</label>
                <p style={{color:'white'}}>Kindly complete the Onboarding Process</p>
                <div className='banner-img'>
                </div>
           </div>
        </div>
        <div className='lg-page-form py-3'>
            <div className='lg-pg-form-box'>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='form-title'>Sign In</label><br/>
                        <label className='form-subtitle'>Kindly Sign in using your credentials</label>
                    </div>
                </div>
                <div className="row form-field">
                    <div className='form-input'>
                        <label>New Password</label>
                        <input type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.handleChange}/>
                    </div>

                    <div className='form-input'>
                        <label>Confirm Password</label>
                        <input type="password" name="confirmPassword" value={this.state.confirmPassword} placeholder="Confirm Password" onChange={this.handleChange}/>
                    </div>
                   
                </div>
               
               
                <div className='row form-submit1'>
                    <button onClick={() => this.handleReset()}>Reset</button>
                </div>

               
                <div className='row create_accnt'>
                    <center>
                        <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up?</span></a>
                        <br/>
                    </center>
                </div>
            </div>
        </div> */}

        <div className='new-lg-page-form'>
            <div className='bg-image'>
                <img src={bgImage}/>
            </div>
            <div className='top-head'>
                <div style={{fontSize:'30px',fontWeight:'bold'}}>Decentralized and uncompromised trust - Blockchain Registry </div> 
                <div style={{fontSize:'18px'}}>Complete the Onboarding Process.</div>
            </div>

            <div className='lg-pg-form-box'>
                <img src={dubailogo} style={{width:'20%'}}/>
                
                <div className="row form-field">
                    <div className='form-input'>
                        {/* <label>New Password</label> */}
                        <input type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.handleChange}/>
                    </div>

                    <div className='form-input'>
                        {/* <label>Confirm Password</label> */}
                        <input type="password" name="confirmPassword" value={this.state.confirmPassword} placeholder="Confirm Password" onChange={this.handleChange}/>
                    </div>
                   
                </div>
                <div className='row form-submit1'>
                    <button onClick={() => this.handleReset()}>Reset</button>
                </div>
                
                <div className='row create_accnt'>
                    <center>
                        <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up</span></a>
                        <br/>
                    </center>
                </div>
            </div>
        </div>
    </div>
    )
  }
}


const mapStateToProps = (state) => {
    return {
        succ_reset: state.user.reset_password,
        fail_reset:state.user.reset_password_err
 
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        resetPassword
      },
      dispatch,
    )
  }

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
