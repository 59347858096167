import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-responsive-modal';
import { useSelector, useDispatch } from 'react-redux'
import { remarkHistory } from '../store/User/action'
import axios from 'axios';
import ModalData from './Modal'
import check_success from '../Assets/success_check.svg'

const SuccessModal = (props) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch()
  const [data,SetData] = useState([])
  // const listData = useSelector((store) => store.Admin.getlistPurchase)
  
  useEffect(() => {
    // console.log(listData)
    // const data=[]
    // for(var i=0;i<5;i++){
    //   data.push(listData.data.result[i])
    // }
    // console.log(data)
    // SetData(data)
  })
  
  console.log(data)

  return (
    <div>
      <ModalData open={open} onClick={() => props.onCloseModal()} center>
      
        <div style={{display:'flex',padding:'10px'}}>          
          {/* <p style={{color:'white',fontSize:'20px',fontWeight:'300',marginTop:'20px',marginLeft:'33%'}}>Blockchain Activity</p>  */}
            
          <a onClick={props.handleOk} style={{color:'white',marginLeft:'95%',marginTop:'1%',width:'25px',height:'25px',cursor:'pointer',textAlign:'center',borderRadius:'50%', backgroundColor:'#21201c'}}>X</a>
        </div> 
        
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',fontWeight:'bold'}}>
            <div>
                <img src={check_success} alt='' style={{width:'5rem',height:'5rem',marginBottom:'40px', marginTop:'20px'}}/>
            </div>
            <div style={{padding:'5px 40px'}}>
                Your Documents Have been Successfully
            </div>
            <div>
                SUBMITTED
            </div>
            <button onClick={props.handleOk} style={{width:'80px',padding:'5px',backgroundColor:'#21201c',color:'white',border:'1px solid #21201c',margin:'5% 0%',borderRadius:'10px'}}> OK</button>


        </div>
        
      </ModalData>
    </div>
  );
};
export default SuccessModal