import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import building from '../../../Assets/Group 11.svg';
import one from '../../../Assets/Group 3.svg';
import two from '../../../Assets/Group 4 (1).svg';
import three from '../../../Assets/Group 5.svg';
import load from '../../../Assets/load';
import LegalDocumentSidebar from '../../../Component/LegalDocumentSideBar';
import { onBoardCompany } from '../../../store/User/action';

class CompanyProfile extends React.Component{
    constructor(props) {
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        super(props);
        this.state = {
            CompanyName: '' ,
            AuthorityName:'',
            CurrentAttestation:'',
            CountryOfIssuance:'',
            errors:'',
            loadState: false,
        };
    }

    componentDidMount(){
        // if(localStorage.getItem('username')===null){
        //     this.props.history.push('/');
        // }
    }

    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }


    handleValidation() {
        const errors = {};
        let formIsValid = true;
        
        if (this.state.CompanyName === undefined || this.state.CompanyName === '') {
          formIsValid = false;
          errors.CompanyName = 'This is a required field';
        }
        if (this.state.AuthorityName === undefined || this.state.AuthorityName === '') {
            formIsValid = false;
            errors.AuthorityName = 'This is a required field';
        }
        if (this.state.CurrentAttestation === undefined || this.state.CurrentAttestation === '') {
            formIsValid = false;
            errors.CurrentAttestation = 'This is a required field';
        }
        if (this.state.CountryOfIssuance === undefined || this.state.CountryOfIssuance === '') {
            formIsValid = false;
            errors.CountryOfIssuance = 'This is a required field';
        }
          
        this.setState({ errors });
        return formIsValid;
      }

    
    handleSubmit=()=>{
    //    this.props.history.push('/user/legal_document/supporting_info');
        if(this.handleValidation()){
            const body={
                company_name:this.state.CompanyName,
                authority_name: this.state.AuthorityName,
                current_attestation: this.state.CurrentAttestation,
                country_of_issuance: this.state.CountryOfIssuance,
            }
            this.props.onBoardCompany(body);
            this.setState({
                loadState: true,
            })
    
        }
    }

    componentDidUpdate(prev){
       
        if(prev.fail_update !== this.props.fail_update){
            this.setState({
                loadState: false,
            })

            if(this.props.fail_update.response?.data?.error==='jwt expired'){
                toast.error('Session Expired');
                this.props.history.push('/');
            }
            toast.error(this.props.fail_update.response.data.message);
        }

        if(prev.succ_update !==this.props.succ_update){

            console.log("compnayid", this.props.succ_update.data.data._id);
            localStorage.setItem('companyId', this.props.succ_update.data.data._id)
            this.props.history.push({pathname: '/user/legal_document/supporting_info', state:{
                companyId: this.props.succ_update?.data?.data?._id
            }})
        }
    }

    render(){
        return(
            <div className='Onboard-page'>
                <div className='lg-page-banner'>
                    <LegalDocumentSidebar {...this.props}/>
                </div>
                <div className='lg-page-form py-3'>
                    <div className='row first-row'>
                        <label>Legal/Corporate Document</label>
                    </div>
                   
                    <div className='row second-form'>
                        <div className='coling'>
                            <img src={one}/>
                        </div>
                        <div className='coling1'>
                            <p style={{color:'#5197cb'}}>Company Profile</p>
                        </div>
                        <div className='coling'>
                            <hr/>
                        </div>
                        <div className='coling'>
                            <img src={two}/>
                        </div>
                        <div className='coling1'>
                            <p>Supporting info</p>
                        </div>
                        <div className='coling'>
                            <hr/>
                        </div>
                        <div className='coling'>
                            <img src={three}/>
                        </div>
                        <div className='coling1'>
                            <p>Blockchain Documents</p>
                        </div>
                    
                    </div>
                    <div className='row third-row'>
                        <div className='col-md-6'>
                            <img src={building}/><p>Company profile <br/><span>Basic details about company.</span></p>
                        </div>
                        {/* <div className='col-md-6 add-user'>
                            <img src={user}/><a>Add New User Role</a>
                        </div> */}
                    </div>
                    
                    <div className='row fivth-row'>
                        <div className='col-md-4'>
                            <label>Company Name <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CompanyName} name="CompanyName" onChange={this.handleChange} placeholder="Enter Company Name"/>
                            {this.state.errors.CompanyName !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CompanyName}</p>
                            } 
                        </div>
                        
                        <div className='col-md-4'>
                            <label>Authority Name<span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.AuthorityName} name="AuthorityName" onChange={this.handleChange} placeholder="Enter the Authority Name"/>
                            {this.state.errors.AuthorityName !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.AuthorityName}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label> Current Attestations <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CurrentAttestation} name="CurrentAttestation" onChange={this.handleChange} placeholder="Enter the Current Attestation"/>
                            {this.state.errors.CurrentAttestation !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CurrentAttestation}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label>Country of Issuance <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CountryOfIssuance} name="CountryOfIssuance" onChange={this.handleChange} placeholder="Enter the Country of Issuance"/>
                            {this.state.errors.CountryOfIssuance !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CountryOfIssuance}</p>
                            } 
                        </div>
                    </div>
                    <div className='row seventh-row'>
                        {this.state.loadState === false ?
                            <button onClick={this.handleSubmit}>Submit</button>
                        :
                        <img src={load} style={{width:'14%',marginLeft:'80%'}}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        succ_update: state.user.onboardingStore,
        fail_update: state.user.onboardingStore_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        onBoardCompany,
      },
      dispatch,
    )
  }


export default connect(mapStateToProps,mapDispatchToProps) (CompanyProfile);

