import React from "react";
import UserSideBar from "../../Component/UserSignBar";
// import dubailogo from '../Assets/dubailogo.png'
import { BsLink45Deg } from "react-icons/bs";
import { FaFilePdf, FaList } from "react-icons/fa";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import chain from "../../Assets/Group 316.svg";
import {
  personalDocument,
  companyDetail,
  companyDocument,
} from "../../store/User/action";

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: true,
      Compdata: "",
      verifiedCount: 0,
      mismatchCount: 0,
      totalCount: 0,
    };
  }
  componentDidMount() {
    this.props.personalDocument();
    if (
      localStorage.getItem("companyId") &&
      localStorage.getItem("companyId") !== undefined
    ) {
      this.props.companyDocument();
    }
  }

  componentDidUpdate(prev) {
    if (prev.docDetail_err !== this.props.docDetail_err) {
      if (this.props.docDetail_err.response?.data?.error === "jwt expired") {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
      toast.error(
        this.props.docDetail_err.response?.data?.message ||
          this.props.docDetail_err.response?.data?.Error ||
          this.props.docDetail_err.response?.data?.error ||
          "Something went wrong"
      );
    }

    if (prev.docDetail !== this.props.docDetail) {
      this.setState((prevState) => ({
        Compdata: [
          ...prevState?.Compdata,
          ...this.props?.docDetail?.data?.data,
        ],
        totalCount:
          prevState.totalCount +
          (this.props.docDetail?.data?.data?.length || 0),
      }));

      Array.isArray(this.props.docDetail?.data?.data) &&
        this.props.docDetail?.data?.data.map((data) => {
          if (data?.isVerified) {
            this.setState((prevState) => ({
              verifiedCount: prevState.verifiedCount + 1,
            }));
          }

          if (data?.isMismatched) {
            this.setState((prevState) => ({
              mismatchCount: prevState.mismatchCount + 1,
            }));
          }
        });
    }

    if (prev.compDocDetail_err !== this.props.compDocDetail_err) {
      if (
        this.props.compDocDetail_err.response?.data?.error === "jwt expired"
      ) {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
      toast.error(
        this.props.compDocDetail_err.response.data.message ||
          this.props.compDocDetail_err.response.data.Error ||
          this.props.docDetail_err.response?.data?.error ||
          "Something went wrong"
      );
    }

    if (prev.compDocDetail !== this.props.compDocDetail) {
      // console.log(this.props.compDocDetail?.data?.data)
      this.setState((prevState) => ({
        Compdata: [
          ...prevState.Compdata,
          ...this.props.compDocDetail?.data?.data,
        ],
        totalCount:
          prevState.totalCount +
          (this.props.compDocDetail?.data?.data?.length || 0),
      }));
      Array.isArray(this.props.compDocDetail?.data?.data) &&
        this.props.compDocDetail?.data?.data?.map((data) => {
          if (data?.isVerified) {
            this.setState((prevState) => ({
              verifiedCount: prevState.verifiedCount + 1,
            }));
          }

          if (data?.isMismatched) {
            this.setState((prevState) => ({
              mismatchCount: prevState.mismatchCount + 1,
            }));
          }
        });
    }
  }

  handleView = () => {
    this.setState({
      view: !this.state.view,
    });
  };

  testing = (data, data1) => {
    console.log(data);
    // console.log(data1)

    sessionStorage.setItem("CertificateShowId", data);
    this.props.history.push({
      pathname: "/user/vault/each_document",
      state: {
        certificateId: data?.hash,
        certificateName: data1,
        data: data,
        isVerified: data?.isVerified,
        isMismatched: data?.isMismatched,
        isRejected: data?.isRejected,
        remarks: data?.rejection_remark,
      },
    });
  };

  render() {
    // console.log(this.state)
    return (
      <div className="listed-page">
        <div className="lg-page-banner">
          <UserSideBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="first-row">
            <label>Dashboard</label>
            {/* <input type="text" name="search" placeholder='Search for a Listed Company'/>
                        <a href><FiSearch style={{color:'#0f878c',marginTop:'-14px'}}/></a> */}
          </div>
          <div className="">
            <p style={{ marginLeft: "1.5%" }}>
              Know your details in a quick overview,
            </p>
          </div>
          <div className="second-row">
            <div className="d-flex pairCard">
              <div className="eachCard">
                <div className="row box-col ">
                  <div className="col-md-8">
                    <p>
                      No of Document Submitted <br />{" "}
                      <span>Total documents</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "#0f878c" }}>
                        {this.state.totalCount}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <img alt="" src={chain} style={{ width: "6%" }} />
              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      Verified <br />{" "}
                      <span>Blockchain based verification has been done.</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "#98CC34" }}>
                        {this.state.verifiedCount}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <img
              className="midChain"
              alt=""
              src={chain}
              style={{ width: "3%" }}
            />

            <div className="d-flex pairCard">
              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      {" "}
                      Not Verified <br />{" "}
                      <span>
                        Blockchain based verification has not been done.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "orange" }}>
                        {this.state.totalCount -
                          (this.state.verifiedCount + this.state.mismatchCount)}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <img alt="" src={chain} style={{ width: "6%" }} />
              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      Mismatched <br />{" "}
                      <span>
                        Verification done and the documents are not matching has
                        mismatch
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "red" }}>{this.state.mismatchCount}</p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row third-row" id="third-row">
            <div className="top-head" style={{}}>
              <p>
                Documents Uploaded
                {/* <span>Complete the Onboarding Process , kindly read the user manual for any assistance.</span> */}
              </p>
              {/* <a ><FaList /> <span>{this.state.view?'Block view':'List view'}</span><br/> 
                            </a> */}
            </div>
            {this.state.view == true ? (
              <div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-1 col">
                    <center>
                      {/* <input type="checkbox"/> &nbsp;  */}
                      <p>S.No</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Document Name</p>
                    </center>
                  </div>
                  <div className="col-md-4 col">
                    <center>
                      <p>Blockchain ID</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Time Stamp</p>
                    </center>
                  </div>
                  <div className="col-md-1 col">
                    <center>
                      <p>Uploaded On</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Verification Status</p>
                    </center>
                  </div>
                </div>
                <hr></hr>
                <div
                  style={{
                    height: "270px",
                    overflowY: "scroll",
                    cursor: "pointer",
                  }}
                >
                  {Array.isArray(this.state.Compdata) &&
                    this.state.Compdata.map((each, index) => {
                      console.log(each.doc_name);
                      return (
                        <>
                          <div
                            className="row"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.testing(each, each?.doc_name)}
                          >
                            <div className="col-md-1 col">
                              <center>
                                {/* <input type="checkbox"/> &nbsp;  */}
                                <p>{index + 1}</p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p style={{ overflowWrap: "break-word" }}>
                                  {each?.doc_name === "Licence_CR"
                                    ? "Licence/CR"
                                    : each?.doc_name === "national_id"
                                    ? "National Id"
                                    : each?.doc_name === "address_proof"
                                    ? "Address Proof"
                                    : each?.doc_name === "Incumberency"
                                    ? "Incumbency"
                                    : each?.doc_name?.replace(
                                        /([a-z])([A-Z])/g,
                                        `$1 $2`
                                      )}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-4 col">
                              <center>
                                <p
                                  style={{
                                    color: "#0f878c",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {each?.hash}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p style={{ overflowWrap: "anywhere" }}>
                                  {each?.createdAt}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-1 col">
                              <center>
                                <p style={{ color: "#1E93BE" }}>
                                  {each?.createdAt?.slice(0, 10)}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p>
                                  <BsLink45Deg
                                    style={{
                                      color: each.isVerified
                                        ? "#438d27"
                                        : each.isMismatched
                                        ? "#cc7a00"
                                        : "#990000",
                                    }}
                                  />
                                  {each.isVerified
                                    ? "Verified"
                                    : each.isMismatched
                                    ? "Mismatched"
                                    : each.isRejected
                                    ? "Rejected"
                                    : "Not verified"}
                                </p>
                              </center>
                            </div>
                          </div>
                          <hr></hr>
                        </>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{ height: "320px", overflowY: "scroll" }}
              >
                {Array.isArray(this.state.Compdata) &&
                  this.state.Compdata.map((each, index) => {
                    return (
                      <div
                        className="col-md-2 col-div"
                        tyle={{ cursor: "pointer" }}
                        style={{ cursor: "pointer" }}
                        // onClick={()=>this.testing(this.state.Compdata.Incorporation)}
                        onClick={() => this.testing(each, each?.doc_name)}
                      >
                        <center>
                          <FaFilePdf
                            style={{ color: "#832120", fontSize: "50px" }}
                          />
                          {/* <p id="para">Example.pdf</p> */}
                          <div style={{ overflowWrap: "anywhere" }}>
                            <label id="label">
                              {each?.doc_name?.replace(
                                /([a-z])([A-Z])/g,
                                `$1 $2`
                              )}
                            </label>
                          </div>
                        </center>
                      </div>
                    );
                  })}
              </div>
            )}
            <br />
          </div>
          <div className="third-row-mobile">
            <div className="top-head" style={{}}>
              <p>
                Documents Uploaded
                {/* <span>Complete the Onboarding Process , kindly read the user manual for any assistance.</span> */}
              </p>
              {/* <a ><FaList /> <span>{this.state.view?'Block view':'List view'}</span><br/> 
                            </a> */}
            </div>
            <div
              style={{
                height: "270px",
                overflowY: "scroll",
                cursor: "pointer",
              }}
            >
              {Array.isArray(this.state.Compdata) &&
                this.state.Compdata.map((each, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between eachCard"
                      onClick={() => this.testing(each, each?.doc_name)}
                    >
                      <div className="d-flex flex-column justify-content-around">
                        <div className="fw-bold px-2">
                          Blockchain Id :{" "}
                          <span
                            className="fw-normal"
                            style={{
                              color: "#0f878c",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {each?.hash?.slice(0, 5) +
                              "..." +
                              each?.hash?.slice(-5)}
                          </span>
                        </div>
                        <div className="fw-bold px-2">
                          Document Name :{" "}
                          <span
                            className="fw-normal"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {each?.doc_name?.replace(
                              /([a-z])([A-Z])/g,
                              `$1 $2`
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-around p-2">
                        <div>
                          <div className="fw-bold px-2">
                            Timestamp :{" "}
                            <span
                              className="fw-normal"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {each?.createdAt}
                            </span>
                          </div>
                          <div className="fw-bold px-2">
                            Uploaded On :{" "}
                            <span
                              className="fw-normal"
                              style={{ color: "#1E93BE" }}
                            >
                              {each?.createdAt?.slice(0, 10)}
                            </span>
                          </div>
                        </div>
                        <div
                          className="fw-bold text-end p-2 "
                          style={{
                            color: each.isVerified
                              ? "#008000"
                              : each.isMismatched
                              ? "#FF0000"
                              : "#fec20c",
                          }}
                        >
                          <BsLink45Deg
                            style={{
                              color: each.isVerified
                                ? "#008000"
                                : each.isMismatched
                                ? "#FF0000"
                                : "#fec20c",
                            }}
                          />
                          {each.isVerified
                            ? "Verified"
                            : each.isMismatched
                            ? "Mismatched"
                            : each.isRejected
                            ? "Rejected"
                            : "Not verified"}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state)
  return {
    docDetail: state.user.personalDoc,
    docDetail_err: state.user.personalDoc_err,
    compDocDetail: state.user.companyDoc,
    compDocDetail_err: state.user.companyDoc_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      personalDocument,
      companyDetail,
      companyDocument,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
