import React from "react";
import { ImCross } from "react-icons/im";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import pdf from "../../../Assets/Group 14.svg";
import one from "../../../Assets/Group 3.svg";
import two from "../../../Assets/Group 4.svg";
import three from "../../../Assets/Group 5.svg";
import load from "../../../Assets/load";
import LegalDocumentSidebar from "../../../Component/LegalDocumentSideBar";
import { postShareHolder } from "../../../store/User/action";

class SupportingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      loadState: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    // console.log(this.props.history.location.state.companyId)
    // if(localStorage.getItem('username')===null){
    //     this.props.history.push('/');
    // }
  }

  componentDidUpdate(prev) {
    // console.log(prev.fail_store, this.props.fail_store)
    if (prev.fail_store !== this.props.fail_store) {
      this.setState({
        loadState: false,
      });
      console.log(this.props.fail_store);
      if (this.props.fail_store?.response?.data?.error === "jwt expired") {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
      toast.error(
        this.props.fail_store?.response?.data?.message ??
          this.props.fail_store?.response?.data?.Error ??
          "Something went wrong"
      );
    }

    if (prev.succ_store !== this.props.succ_store) {
      toast.success("Data Stored Successfully");
      // swal({text:"Data Stored Successfully"}).then(res => {
      //   })
      this.setState({
        loadState: false,
      });
      // this.handlePassData()
      this.props.history.push({
        pathname: "/user/legal_document/documents",
        state: {
          companyId: this.props.history.location.state.companyId,
        },
      });
    }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handlePassport = (e) => {
    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    document.getElementById(`upload-${name}`).value = "";
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    if (this.state.ShareHolder === undefined || this.state.ShareHolder === "") {
      formIsValid = false;
      errors.ShareHolder = "This is a required field";
    }
    if (this.state.SelectRole === undefined || this.state.SelectRole === "") {
      formIsValid = false;
      errors.SelectRole = "This is a required field";
    }
    if (this.state.national === undefined || this.state.national === "") {
      formIsValid = false;
      errors.national = "This is a required field";
    }
    if (
      this.state.holdingPercentage === undefined ||
      this.state.holdingPercentage === ""
    ) {
      formIsValid = false;
      errors.holdingPercentage = "This is a required field";
    } else if (this.state.holdingPercentage > 100) {
      formIsValid = false;
      errors.holdingPercentage = "Invalid";
    }
    if (
      this.state.ContactNumber === undefined ||
      this.state.ContactNumber === ""
    ) {
      formIsValid = false;
      errors.ContactNumber = "This is a required field";
    } else if (!/^[0-9]{10}$/i.test(this.state.ContactNumber)) {
      formIsValid = false;
      errors.ContactNumber = "Invalid contact no";
    }
    if (
      this.state.AdditionalDetail === undefined ||
      this.state.AdditionalDetail === ""
    ) {
      formIsValid = false;
      errors.AdditionalDetail = "This is a required field";
    }
    if (!this.state.OfficialMail) {
      formIsValid = false;
      errors.OfficialMail = "Required";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(
        this.state.OfficialMail
      )
    ) {
      formIsValid = false;
      errors.OfficialMail = "Invalid email address";
    }

    // if (this.state.file1 === undefined || this.state.file1 === '') {
    //     formIsValid = false;
    //     errors.file1 = 'This is a required field';
    //   }
    //   if (this.state.file2 === undefined || this.state.file2 === '') {
    //     formIsValid = false;
    //     errors.file2 = 'This is a required field';
    //   }
    //   if (this.state.file4 === undefined || this.state.file4 === '') {
    //     formIsValid = false;
    //     errors.file4 = 'This is a required field';
    //   }
    //   if (this.state.file5 === undefined || this.state.file5 === '') {
    //     formIsValid = false;
    //     errors.file5 = 'This is a required field';
    //   }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = () => {
    if (this.handleValidation()) {
      this.setState({
        loadState: true,
      });

      var min = 1;
      var max = 1000;
      var rand = Math.round(min + Math.random() * (max - min));

      console.log(rand);
      localStorage.setItem("random", rand);

      console.log("File1", this.state.file1);

      const formData = new FormData();

      formData.append("shareHolder_name", this.state.ShareHolder);
      formData.append("role_in_company", this.state.SelectRole);
      formData.append("national_id", this.state.national);
      formData.append("share_holding", this.state.holdingPercentage);
      formData.append("mail_address", this.state.OfficialMail);
      formData.append("contact_number", this.state.ContactNumber);
      formData.append("additional_details", this.state.AdditionalDetail);

      // Append files to FormData
      // formData.append('ValidPassportCopy', this.state.file1 ?? '');
      // formData.append('NationalId', this.state.file2 ?? '');
      // formData.append('NOC', this.state.file3 ?? '');
      // formData.append('UtilityBillForAddressProof', this.state.file4 ?? '');
      // formData.append('BussinessProfile', this.state.file5 ?? '');

      this.props.postShareHolder({
        body: formData,
        companyId: this.props.history.location.state.companyId,
      });
    }
  };

  render() {
    // console.log(this.state)
    return (
      <div className="Onboard-page">
        <ToastContainer />
        <div className="lg-page-banner">
          <LegalDocumentSidebar {...this.props} />
        </div>
        <div className=" lg-page-form py-3">
          <div className="row first-row">
            <label>Supporting Info</label>
          </div>
          {/* <div className='row'>
                        <p style={{marginLeft:'1.5%'}}>Details about your company Directors,list all of the here</p>
                    </div> */}
          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Company Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Supporting Info</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p>Blockchain Documents</p>
            </div>
          </div>
          <div className="row fivth-row">
            <div className="col-md-4">
              <label>
                Name of the Share Holder{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.ShareHolder}
                name="ShareHolder"
                onChange={this.handleChange}
                placeholder="Enter Share Holder Name"
              />
              {this.state.errors.ShareHolder !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.ShareHolder}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Select role on the company{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.SelectRole}
                name="SelectRole"
                onChange={this.handleChange}
                placeholder="Enter role of the company"
              />
              {this.state.errors.SelectRole !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.SelectRole}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                National ID{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.national}
                name="national"
                onChange={this.handleChange}
                placeholder="Enter National ID"
              />
              {this.state.errors.national !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.national}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Share holding In Percentage{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.holdingPercentage}
                max={100}
                name="holdingPercentage"
                onChange={this.handleChange}
                placeholder="Enter Share holding percentage"
              />
              {this.state.errors.holdingPercentage !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.holdingPercentage}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Official Mail Address{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.OfficialMail}
                name="OfficialMail"
                onChange={this.handleChange}
                placeholder="Enter your official mail"
              />
              {this.state.errors.OfficialMail !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.OfficialMail}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Contact Number{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                maxLength="10"
                value={this.state.ContactNumber}
                name="ContactNumber"
                onChange={this.handleChange}
                placeholder="Enter your contact number"
              />
              {this.state.errors.ContactNumber !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.ContactNumber}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Additional Details{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.AdditionalDetail}
                name="AdditionalDetail"
                onChange={this.handleChange}
                placeholder=""
              />
              {this.state.errors.AdditionalDetail !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.AdditionalDetail}
                </p>
              )}
            </div>
          </div>

          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    succ_store: state.user.shareholderStore,
    fail_store: state.user.shareholderStore_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postShareHolder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportingInfo);
