import React from "react";
import { ImCross } from "react-icons/im";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import building from "../../../Assets/Group 11.svg";
import pdf from "../../../Assets/Group 14.svg";
import one from "../../../Assets/Group 3.svg";
import two from "../../../Assets/Group 4.svg";
import three from "../../../Assets/Group 5 (1).svg";
import load from "../../../Assets/load";
import IndividualDocumentSidebar from "../../../Component/IndividualDocumentSideBar";
import SuccessModel from "../../../Component/SuccessModal";
import { degreeCertificate } from "../../../store/User/action";

class PersonalDegreeDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfDocuments: [],
      count: 0,
      errors: "",
      loadState: false,
      addState: false,
    };
  }

  componentDidMount() {
    // if(localStorage.getItem('username')===null){
    //     this.props.history.push('/');
    // }
  }

  componentDidUpdate(prev) {
    if (prev.fail_documentUpload !== this.props.fail_documentUpload) {
      console.log(this.props.fail_documentUpload);
      toast.error(
        this.props?.fail_documentUpload?.response?.data?.message ||
          this.props?.fail_documentUpload?.response?.data?.Error
      );
      this.setState({
        loadState: false,
      });
      if (
        this.props.fail_documentUpload?.response?.data?.error === "jwt expired"
      ) {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
    }

    if (prev.succ_documentUpload !== this.props.succ_documentUpload) {
      console.log(this.props.succ_documentUpload);
      this.setState({
        loadState: false,
        addState: !this.state.addState,
      });
    }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handlePassport = (e) => {
    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    // document.getElementById(`upload-${name}`).value='';
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    this.state.noOfDocuments.map((data) => {
      if (this.state[data] === undefined || this.state[data] === "") {
        formIsValid = false;
        errors[data] = "Name is required field";
      }

      if (
        this.state[data + " file"] === undefined ||
        this.state[data + " file"] === ""
      ) {
        formIsValid = false;
        errors[data + " file"] = "File is required field";
      }
    });

    this.setState({ errors });
    console.log(this.state);
    return formIsValid;
  }

  handleSubmit = () => {
    if (this.handleValidation()) {
      this.setState({
        loadState: true,
      });
      var formData = new FormData();

      this.state.noOfDocuments.map((data) => {
        formData.append(this.state[data], this.state[data + " file"]);
      });

      console.log(formData);
      console.log(this.state);
      let individualId;
      if (
        localStorage.getItem("individualId") &&
        localStorage.getItem("individualId") !== undefined
      ) {
        individualId = localStorage.getItem("individualId");
      }

      this.props.degreeCertificate({
        body: formData,
        individualDocId:
          individualId || this.props.history.location.state.individualDocId,
      });
    }
  };

  handleOk = () => {
    this.setState({
      addState: !this.state.addState,
    });
    this.props.history.push("/user/Dashboard");
  };

  handleAddDocuments = () => {
    this.setState({
      count: this.state.count + 1,
      noOfDocuments: [
        ...this.state.noOfDocuments,
        "Document " + (this.state.count + 1),
      ],
    });
  };

  removeDocument = (data) => {
    console.log(data);
    this.setState({
      count: this.state.count - 1,
      noOfDocuments: this.state.noOfDocuments.filter((item) => item !== data),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="Onboard-page">
        {this.state.addState === true && (
          <SuccessModel handleOk={this.handleOk} />
        )}
        <ToastContainer />
        <div className="lg-page-banner">
          <IndividualDocumentSidebar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="row first-row" style={{ display: "flex" }}>
            <label>Individual Document</label>
          </div>

          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Personal Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Supporting Document</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Blockchain Document</p>
            </div>
          </div>
          <div className="row third-row">
            <div className="col-md-6">
              <img src={building} />
              <p>
                Register Documents in Blockchain <br />
                <span>
                  Upload documents to be registered in blockchain and complete
                  the onboarding (Kindly scan both sides of document*)
                </span>
              </p>
            </div>
            <div className="col-md-6 add-user">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
                onClick={this.handleAddDocuments}
              >
                Add Document +
              </div>
            </div>
          </div>

          <div className="row sixth-row">
            {this.state.noOfDocuments.map((data) => (
              <div className="col-md-4 ">
                <p className="d-flex justify-content-between">
                  <span>
                    {data}{" "}
                    <span style={{ color: "red", marginLeft: "10px" }}>*</span>
                  </span>
                  <span
                    className=""
                    style={{ cursor: "pointer" }}
                    onClick={() => this.removeDocument(data)}
                  >
                    X
                  </span>
                </p>

                <div className="d-flex flex-column">
                  <p>Valid Format PDF.</p>
                  <div className="d-flex">
                    <div className="w-75">
                      <input
                        type="text"
                        value={this.state[data]}
                        name={data}
                        onChange={this.handleChange}
                        placeholder="Enter the name of document"
                      />
                    </div>
                    <div className="w-25">
                      <label for={data + " file"}>Upload</label>
                      <input
                        type="file"
                        id={data + " file"}
                        name={data + " file"}
                        accept=".pdf"
                        hidden
                        onChange={this.handlePassport}
                      />
                    </div>
                  </div>
                </div>
                {this.state.errors[data] !== undefined && (
                  <p
                    style={{ color: "red", fontSize: "13px", marginTop: "0px" }}
                  >
                    {this.state.errors[data]}
                  </p>
                )}
                {this.state.errors[data + " file"] !== undefined && (
                  <p
                    style={{ color: "red", fontSize: "13px", marginTop: "0px" }}
                  >
                    {this.state.errors[data + " file"]}
                  </p>
                )}
                {this.state[data + " file"] !== undefined && (
                  <div className="paradiv">
                    <img src={pdf} />
                    <a id="para">{this.state[data + " file"].name}</a>{" "}
                    <ImCross
                      style={{
                        fontSize: "10px",
                        marginTop: "3%",
                        marginLeft: "10px",
                      }}
                      onClick={() => this.cleanFile(data + " file")}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    succ_documentUpload: state.user.degreeCertificate,
    fail_documentUpload: state.user.degreeCertificate_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      degreeCertificate,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDegreeDocument);
