import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';


const loader = document.querySelector("#preloader");

const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("loader--hide");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App hideLoader={hideLoader} showLoader={showLoader} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)

reportWebVitals();
