import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import building from "../../../Assets/Group 11.svg";
import one from "../../../Assets/Group 3.svg";
import two from "../../../Assets/Group 4 (1).svg";
import three from "../../../Assets/Group 5.svg";
import load from "../../../Assets/load";
import IndividualDocumentSidebar from "../../../Component/IndividualDocumentSideBar";
import { personalProfile } from "../../../store/User/action";

class PersonalProfile extends React.Component {
  constructor(props) {
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    super(props);
    this.state = {
      Name: "",
      Address: "",
      emailId: "",
      Nationality: "",
      errors: "",
      loadState: false,
    };
  }

  componentDidMount() {
    // if(localStorage.getItem('username')===null){
    //     this.props.history.push('/');
    // }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    if (this.state.Name === undefined || this.state.Name === "") {
      formIsValid = false;
      errors.Name = "This is a required field";
    }
    if (this.state.Address === undefined || this.state.Address === "") {
      formIsValid = false;
      errors.Address = "This is a required field";
    }
    if (this.state.emailId === undefined || this.state.emailId === "") {
      formIsValid = false;
      errors.emailId = "This is a required field";
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.state.emailId)
    ) {
      formIsValid = false;
      errors.emailId = "Invalid Email";
    }

    if (this.state.Nationality === undefined || this.state.Nationality === "") {
      formIsValid = false;
      errors.Nationality = "This is a required field";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = () => {
    //    this.props.history.push('/user/individual_document/supporting_document');
    if (this.handleValidation()) {
      const body = {
        name: this.state.Name,
        address: this.state.Address,
        email_id: this.state.emailId,
        nationality: this.state.Nationality,
      };
      this.props.personalProfile(body);
      this.setState({
        loadState: true,
      });
    }
  };

  componentDidUpdate(prev) {
    if (prev.fail_update !== this.props.fail_update) {
      this.setState({
        loadState: false,
      });

      if (this.props.fail_update?.response?.data?.error === "jwt expired") {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
      toast.error(this.props.fail_update.response.data.message);
    }

    if (prev.succ_update !== this.props.succ_update) {
      console.log("compnayid", this.props.succ_update.data.data._id);
      localStorage.setItem(
        "individualId",
        this.props.succ_update.data.data._id
      );

      this.props.history.push({
        pathname: "/user/individual_document/supporting_document",
        state: {
          individualDocId: this.props.succ_update?.data?.data?._id,
        },
      });
    }
  }

  render() {
    return (
      <div className="Onboard-page">
        <div className="lg-page-banner">
          <IndividualDocumentSidebar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="row first-row">
            <label>Individual/Personal Document</label>
          </div>

          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Personal Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p>Supporting Document</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p>Blockchain Document</p>
            </div>
          </div>
          <div className="row third-row">
            <div className="col-md-6">
              <img src={building} />
              <p>
                Personal profile <br />
                <span>Basic details about individual.</span>
              </p>
            </div>
          </div>

          <div className="row fivth-row">
            <div className="col-md-4">
              <label>
                Name <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.Name}
                name="Name"
                onChange={this.handleChange}
                placeholder="Enter Name"
              />
              {this.state.errors.Name !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.Name}
                </p>
              )}
            </div>

            <div className="col-md-4">
              <label>
                Address
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.Address}
                name="Address"
                onChange={this.handleChange}
                placeholder="Enter the Address"
              />
              {this.state.errors.Address !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.Address}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                {" "}
                Email ID{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="email"
                value={this.state.emailId}
                name="emailId"
                onChange={this.handleChange}
                placeholder="Enter the email id"
              />
              {this.state.errors.emailId !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.emailId}
                </p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Nationality{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </label>
              <input
                type="text"
                value={this.state.Nationality}
                name="Nationality"
                onChange={this.handleChange}
                placeholder="Enter the Nationality"
              />
              {this.state.errors.Nationality !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.Nationality}
                </p>
              )}
            </div>
          </div>
          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    succ_update: state.user.personalProfile,
    fail_update: state.user.personalProfile_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      personalProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalProfile);
