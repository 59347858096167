import { Component } from 'react';
import { FiMenu, FiSettings } from 'react-icons/fi';
import { RiArrowDropDownLine } from "react-icons/ri";
import user from '../../Assets/Icon metro-user.svg';
import logo from '../../Assets/Blockhain_reg_new.png';
import Loader from  '../../Component/Loader';

class GeneralLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notification_count: 0,
            open: false,
            loading:true,
        }
    }

    handledown = () => {
        this.setState({
            open: !this.state.open
        })
    }

    handleLogout = () => {
        localStorage.clear();
        this.setState({
            open: false
        })
        this.props.history.push('/')
    }

    handleLogo = () => {
        this.props.history.push('/user/Dashboard')
    }
    handleVideoEnd=()=>{
        this.setState({
            loading:false
        })
    }

    render() {

        return ((this.state.loading?<Loader handleVideoEnd={this.handleVideoEnd}/>:
            (<div className='general-layout'>
                {/* <div className='gn-layout-header'>
                    <div className='gn-ly-header-left' onClick={this.handleLogo} style={{ cursor: "pointer", }}>
                        <img src={logo} id="big-logo" alt="blockchain registry"  />
                    </div>
                    <div className='gn-ly-header-right'>
                        <div className='input-box'>
                            <img src={user} alt="user" />
                            <p>Sign In<br /> <span>Sign In Using Mail</span></p>
                        </div>
                        <div className="dropdown show">
                            <div className='bell-box' >
                            </div>
                        </div>
                        <div className='settings-box'>
                            <FiSettings id="icon_setting"></FiSettings>
                        </div>
                        {localStorage.getItem('username')&& 
                        <div className='settings-box1'>
                            <RiArrowDropDownLine id="icon_drop" onClick={this.handledown} />
                        </div> }
                        
                        <div className='mobile-menu'>
                            <FiMenu></FiMenu>
                        </div>
                    </div>
                    {this.state.open == true &&
                        <ul style={{ background: '#000000', marginLeft: '83%', marginTop: '6%', padding: '10px 20px', zIndex: '1' }}>
                            <li style={{ color: 'lightgrey', listStyle: 'none', fontWeight: 'bold', cursor: 'pointer' }} onClick={this.handleLogout}>Logout</li>
                        </ul>
                    }

                </div>
                <div className='gn-middle-header'>

                </div> */}
                <div className='' style={{height:'100%', width:'100%'}}>
                    { this.props.children ?? null }

                </div>
            </div>
        )));
    }
}

export default GeneralLayout;