import React, { PureComponent } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import load from "../Assets/loader";
import QRCode from "qrcode";
import { PDFDocument, rgb } from "pdf-lib";
import logo from "../Assets/Logo-08.png";

export default class PDFView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numPages: "",
      modifiedPDF: "",
      url: "",
    };
  }

  componentDidMount() {
    this.setState({
      url: `https://node.blockchainregistry.ai/view_document/${this.props?.certificate}`,
    });

    if (this.props.verified) {
      this.generatePdf();
    }
    console.log(this.props);
  }

  generatePdf() {
    const modifyPDF = async () => {
      // const pdfUrl = `https://gateway.pinata.cloud/ipfs/${this.props?.certificate}`;
      const pdfUrl = `https://node.blockchainregistry.ai/view_document/${this.props?.certificate}`;
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pages = pdfDoc.getPages();

      this.setState({
        numPages: pdfDoc.getPageCount(),
      });

      const qrCodeDataUrl = await QRCode.toDataURL(this.props.url);
      const qrCodeImage = await pdfDoc.embedPng(qrCodeDataUrl);

      const imageBytes = await fetch(logo).then((res) => res.arrayBuffer());
      const logoImage = await pdfDoc.embedPng(imageBytes);

      const { width, height } = pages[0].getSize();

      pages.forEach((page) => {
        const { width, height } = page.getSize();
        page.drawImage(logoImage, {
          x: width - 150,
          y: -60,
          width: 200,
          height: 200,
        });

        page.drawText(`Verified HashId- ${this.props.certificate}`, {
          x: 100,
          y: 60,
          size: 10,
          color: rgb(0, 0.53, 0.71),
        });

        page.drawText(`This document is verified by Blockchain Registry`, {
          x: 120,
          y: 40,
          size: 10,
          color: rgb(1, 0.53, 0.71),
        });
        page.drawText(
          ` Verify the document by scanning the QR code. Any correction will render the document invalid`,
          {
            x: 90,
            y: 25,
            size: 10,
            color: rgb(1, 0.53, 0.71),
          }
        );
        page.drawImage(qrCodeImage, {
          x: 0,
          y: 0,
          width: 80,
          height: 80,
        });
      });

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      console.log("gen3");
      this.setState({
        modifiedPDF: URL.createObjectURL(modifiedPdfBlob),
      });
    };

    modifyPDF();
  }

  render() {
    {
      console.log(
        `https://node.blockchainregistry.ai/view_document/${this.props?.certificate}`
      );
    }

    return (
      <div className="w-100 h-100 pdf">
        {this.props.certificate !== undefined ? (
          this.props.verified ? (
            <embed src={this.state.modifiedPDF} width="100%" height="100%" />
          ) : (
            <embed
              // src={`https://gateway.pinata.cloud/ipfs/${
              //   this.props?.certificate ?? ""
              // }`}
              // src={`http://3.28.231.133:8084/view_document/${this.props?.certificate}}`}
              src={this.state.url}
              width="100%"
              height="100%"
            />
          )
        ) : (
          <img src={load} style={{ marginTop: "25%", marginLeft: "25%" }} />
        )}
      </div>
    );
  }
}
