import React from 'react';
import GoogleLogin from 'react-google-login';
import banner1 from '../Assets/Business ethics-pana (1).svg'
import { FcGoogle } from "react-icons/fc";
import minilogo from '../Assets/Path 1350.svg'
import '../style/_dashboard.scss'
import Ellipse from '../Assets/Icon metro-user.svg'
import menu1 from '../Assets/Group 187.png'
import menu2 from '../Assets/Group 185.png'
import menu3 from '../Assets/Group 186.png'
import menu4 from '../Assets/Group 182.png'

class SmartContractSidebar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    




    handleRedirectMenu=(data)=>{
        this.props.history.push("/"+data);
    }

    render(){
        const data = window.location.href.split('/')[5]
        return(
            <div className="sideBar">
                <div className="row img-area">
                        <img src={Ellipse}/>
                        <p><div>{localStorage.getItem('username')}</div> <span>Blockchain Registry</span></p>
                        {/* <p>Corporate User <br/> <span>BlockChain Registry</span></p> */}
                </div>
                <div className="menu_area">
                    <div className="row" style={{marginTop:'15',cursor:'pointer'}} >
                        {/* <img src={menu4}/> */}
                        <p style={{color:data === 'basic_details' ? 'white' : '#aaaaaa',marginLeft:'10%'}}>Basic Details</p>
                    </div>
                    <div className="row" style={{cursor:'pointer'}} >
                        {/* <img src={menu2}/> */}
                        <p style={{color:data === 'documents' ? 'white' : '#aaaaaa',marginLeft:'10%'}}> Documents</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default SmartContractSidebar

