import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postForgetPasswordSendMail,otpVerification } from '../store/User/action';
import { ToastContainer, toast } from 'react-toastify';

import bgImage from '../Assets/shared image.jpg'
import dubailogo from '../Assets/Blockhain_reg_new.png'

class ForgetPassword extends Component {

    constructor(props){
        super(props);
        this.state={
            otp:'',
            username:"",
            showOtp:false,
        }
    }

    handleSendMail=()=>{
        this.props.postForgetPasswordSendMail({username:this.state.username})
    }

    componentDidUpdate(prev){
        if(prev.succ_email_sent!==this.props.succ_email_sent){
            toast.success("Enter the OTP sent to your email");
            this.setState({
                showOtp:true
            })
        }

        if(prev.fail_email_sent!==this.props.fail_email_sent){
            console.log(this.props.fail_email_sent?.response?.data?.message);
            toast.error(this.props.fail_email_sent?.response?.data?.message||'Error in sending mail');
        }

        if(prev.otp_succ!==this.props.otp_succ){
            toast.success("OTP Verified");
            this.props.history.push({
                pathname: "/reset", state: {
                    userId:this.props.otp_succ?.data?.data?._id
                }
            });
        }

        if(prev.otp_fail!==this.props.otp_fail){
            console.log(this.props.otp_fail?.response?.data?.Error);
            if(this.props.otp_fail?.response?.data?.Error||'id is not defined'){
                toast.error("Invalid Otp");
                this.setState({
                    otp:''
                })
            }
            // toast.error(this.props.otp_fail?.response?.data?.message);
        }
    }

    handleSendOtp=()=>{
        this.props.otpVerification({otp:this.state.otp,email:this.state.username})
    }

    handleRedirect=()=>{
        this.props.history.push('/SignUp')
    }

    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})     
    }

  render() {
    return (
        <div className='login-page'>
        <ToastContainer />
        {/* <div className='lg-page-banner'>
           <div className='banner'>
                <label style={{color:'white'}}>Decentralized and Uncompromised trust <br/> BlockChain Registry</label>
                <p style={{color:'white'}}>Kindly complete the Onboarding Process</p>
                <div className='banner-img'>
                </div>
           </div>
        </div> */}
        {/* <div className='lg-page-form py-3'>
            <div className='lg-pg-form-box'>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='form-title'>Sign In</label><br/>
                        <label className='form-subtitle'>Kindly Sign in using your credentials</label>
                    </div>
                </div>
                <div className="row form-field">
                    <div className='form-input'>
                        <label>Enter your Email ID</label>
                        <input type="text" name="username" value={this.state.username} placeholder="E-Mail ID" onChange={this.handleChange}/>
                    </div>
                   
                </div>
                {this.state.showOtp && <div className='row form-field'>
                    <div className='form-input'>
                        <div><label>OTP</label></div>
                        <input type="text" name="otp" value={this.state.otp} placeholder="OTP" onChange={this.handleChange} maxLength={6}/>
                    </div>
                </div>}
               
                <div className='row form-submit1'>
                {this.state.showOtp ? (
                    <button onClick={() => this.handleSendOtp()}>Submit</button>
                ) : (
                    <button onClick={() => this.handleSendMail()}>Send OTP</button>
                )}
                </div>

               
                <div className='row create_accnt'>
                    <center>
                        <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up</span></a>
                        <br/>
                    </center>
                </div>
            </div>  
        </div> */}
           <div className='new-lg-page-form'>
                <div className='bg-image'>
                    <img src={bgImage}/>
                </div>
                <div className='top-head'>
                    <div style={{fontSize:'30px',fontWeight:'bold'}}>Decentralized and uncompromised trust - Blockchain Registry </div> 
                    <div style={{fontSize:'18px'}}>Complete the Onboarding Process.</div>
                </div>

                <div className='lg-pg-form-box'>
                    <img src={dubailogo} style={{width:'20%'}}/>
                    
                    <div className="row form-field">
                        <div className='form-input'>
                            {/* <label>Enter your Email ID</label> */}
                            <input type="text" name="username" value={this.state.username} placeholder="E-Mail ID" onChange={this.handleChange}/>
                        </div>
                    
                    </div>
                    {this.state.showOtp && <div className='row form-field'>
                        <div className='form-input'>
                            {/* <div><label>OTP</label></div> */}
                            <input type="text" name="otp" value={this.state.otp} placeholder="OTP" onChange={this.handleChange} maxLength={6}/>
                        </div>
                    </div>}
                
                    <div className='row form-submit1'>
                    {this.state.showOtp ? (
                        <button onClick={() => this.handleSendOtp()}>Submit</button>
                    ) : (
                        <button onClick={() => this.handleSendMail()}>Send OTP</button>
                    )}
                    </div>

                
                    <div className='row create_accnt'>
                        <center>
                            <a onClick={() => this.handleRedirect()}>Don’t have an account ? <span>Sign Up</span></a>
                            <br/>
                        </center>
                    </div>
                </div>

            </div>
    </div>
    )
  }
}


const mapStateToProps = (state) => {
    return {
        succ_email_sent: state.user.forget_password_send,
        fail_email_sent: state.user.forget_password_send_err,
        otp_succ: state.user.otp_verification,
        otp_fail: state.user.otp_verification_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        postForgetPasswordSendMail,
        otpVerification
      },
      dispatch,
    )
  }

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
