import React from 'react';
import GoogleLogin from 'react-google-login';
import banner1 from '../../../Assets/Business ethics-pana (1).svg'
import { FiSearch } from "react-icons/fi";
import minilogo from '../../../Assets/Path 1350.svg'
import LegalDocumentSidebar from '../../../Component/LegalDocumentSideBar'
import { BsLink45Deg } from "react-icons/bs";
import { FaAngleDoubleLeft , FaAngleDoubleRight } from "react-icons/fa";
import one from '../../../Assets/Group 3.svg'
import two from '../../../Assets/Group 4 (1).svg'
import three from '../../../Assets/Group 5.svg'
import four from '../../../Assets/Group 6.svg'
import building from '../../../Assets/Group 11.svg'
import user from '../../../Assets/Group 12.svg'
import { ImCross } from "react-icons/im";
import pdf from '../../../Assets/Group 14.svg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {onBoardCompany} from '../../../store/User/action'
import { ToastContainer, toast } from 'react-toastify';
import load from '../../../Assets/load'
import SmartContractSidebar from '../../../Component/SmartContractSideBar';

class SmBasicDetails extends React.Component{
    constructor(props) {
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        super(props);
        this.state = {
            Name:'',
            Address:'',
            Id:'',
            errors:'',
            loadState: false,
        };
    }

    componentDidMount(){
        // if(localStorage.getItem('username')===null){
        //     this.props.history.push('/');
        // }
    }

    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }


  
    handleValidation() {
        const errors = {};
        let formIsValid = true;
        
        if (this.state.Name === undefined || this.state.Name === '') {
          formIsValid = false;
          errors.Name = 'This is a required field';
        }
        if (this.state.Address === undefined || this.state.Address === '') {
            formIsValid = false;
            errors.Address = 'This is a required field';
          }
          if (this.state.Id === undefined || this.state.Id === '') {
            formIsValid = false;
            errors.Id = 'This is a required field';
          }
         
        this.setState({ errors });
        return formIsValid;
      }


    handleSubmit=()=>{
        if(this.handleValidation()){
            
            this.props.history.push({
                pathname: '/user/smart_contract/documents',
                state: {
                  name: this.state.Name,
                  address: this.state.Address,
                  ID: this.state.Id,
                }
              });
        }
    }

    componentDidUpdate(prev){
       
        // if(prev.fail_update !== this.props.fail_update){
 
        //     this.setState({
        //         loadState: false,
        //     })

        //     if(!localStorage.getItem('refresh_token')){
        //         toast.error('Session Expired');
        //         this.props.history.push('./');
        //     }
        //     toast.error(this.props.fail_update.response.data.message);
        // }
        // if(prev.succ_update !==this.props.succ_update){
        //     console.log("compnayid", this.props.succ_update.data.data._id)
        //     this.props.history.push({pathname: '/Company_Onboard', state:{
        //         companyId: this.props.succ_update?.data?.data?._id
        //     }})
        // }
    }

    render(){
        return(
            <div className='Onboard-page'>
                <div className='lg-page-banner'>
                    <SmartContractSidebar {...this.props}/>
                </div>
                <div className='lg-page-form py-3'>
                    <div className='row first-row'>
                        <label>Smart Contract</label>
                    </div>
                    
                    <div className='row second-form'>
                        <div className='coling'>
                            <img src={one}/>
                        </div>
                        <div className='coling1'>
                            <p style={{color:'#5197cb'}}>Basic Details</p>
                        </div>
                        <div className='coling'>
                            <hr/>
                        </div>
                        <div className='coling'>
                            <img src={two}/>
                        </div>
                        <div className='coling1'>
                            <p>Blockchain Documents</p>
                        </div>
                        
                    
                    </div>
                    <div className='row third-row'>
                        <div className='col-md-6'>
                            <img src={building}/><p>Basic Information <br/><span>Please enter basic details asked below.</span></p>
                        </div>
                    </div>
                  
                    <div className='row fivth-row'>
                        <div className='col-md-4'>
                            <label>Name <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.Name} name="Name" onChange={this.handleChange} placeholder="Enter Name"/>
                            {this.state.errors.Name !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.Name}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label style={{width:'80px'}}>Email ID <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="email" value={this.state.Id} name="Id" onChange={this.handleChange} placeholder="Enter the email id"/>
                            {this.state.errors.Id !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.Id}</p>
                            } 
                        </div>
                        
                        <div className='col-md-4'>
                            <label>Address<span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.Address} name="Address" onChange={this.handleChange} placeholder="Enter the Address"/>
                            {this.state.errors.Address !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.Address}</p>
                            } 
                        </div>
                        
                    </div>
                    <div className='row seventh-row'>
                        {this.state.loadState === false ?
                            <button onClick={this.handleSubmit}>Submit</button>
                        :
                        <img src={load} style={{width:'14%',marginLeft:'80%'}}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // succ_update: state.admin.onboardingStore,
        // fail_update: state.admin.onboardingStore_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        // onBoardCompany,
      },
      dispatch,
    )
  }


export default connect(mapStateToProps,mapDispatchToProps) (SmBasicDetails);

