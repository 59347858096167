import {
  LOADING,
  REGISTER_API_ERR,
  REGISTER_API,
  SIGN_IN_API,
  SIGN_IN_API_ERR,
  POST_ONBOARDING_STORE_ERR,
  POST_ONBOARDING_STORE,
  UPLOAD_DOCUMENT_ERR,
  UPLOAD_DOCUMENT,
  COMPANY_DETAIL,
  COMPANY_DETAIL_ERR,
  CERTICATE_DETAIL,
  CERTICATE_DETAIL_ERR,
  POST_SHAREHOLDER_STORE,
  POST_SHAREHOLDER_STORE_ERR,
  DASHBOARD_DETAIL,
  DASHBOARD_DETAIL_ERR,
  DASHBOARD_COMPANIES_DETAIL,
  DASHBOARD_COMPANIES_DETAIL_ERR,
  DASHBOARD_COMPANY_DOC,
  DASHBOARD_COMPANY_DOC_ERR,
  FORGET_PASSWORD_SEND,
  FORGET_PASSWORD_SEND_ERR,
  OTP_VERIFICATION,
  OTP_VERIFICATION_ERR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERR,
  VERIFICATION_DOC,
  VERIFICATION_DOC_ERR,
  PERSONAL_PROFILE,
  PERSONAL_PROFILE_ERR,
  SUPPORTING_DOC,
  SUPPORTING_DOC_ERR,
  DEGREE_CERTIFICATE,
  DEGREE_CERTIFICATE_ERR,
  SMART_CONTRACT,
  SMART_CONTRACT_ERR,
  PERSONAL_DOC_ERR,
  PERSONAL_DOC,
  COMPANY_DOC_ERR,
  COMPANY_DOC,
  RE_UPLOAD_ERR,
  RE_UPLOAD,
} from "./types";
// import
const initialState = {
  message: "waiting for the reducer",
  loading: true,
  sign_in_err: "",
  sign_in_succ: "",
  register_err: "",
  register_succ: "",
  onboardingStore_err: "",
  onboardingStore: "",
  shareholderStore: "",
  shareholderStore_err: "",
  upload_succ: "",
  upload_err: "",
  getCompny_err: "",
  getCompny: "",
  getCertificate_err: "",
  getCertificate: "",
  dashboard: "",
  dashboard_err: "",
  dashboard_companies: "",
  dashboard_companies_err: "",
  dashboard_company_doc: "",
  dashboard_company_doc_err: "",
  forget_password_send: "",
  forget_password_send_err: "",
  otp_verification: "",
  otp_verification_err: "",
  reset_password: "",
  reset_password_err: "",
  verify_doc: "",
  verify_doc_err: "",
  personalProfile: "",
  personalProfile_err: "",
  supportingDoc: "",
  supportingDoc_err: "",
  degreeCertificate: "",
  degreeCertificate_err: "",
  smartContract: "",
  smartContract_err: "",
  personalDoc: "",
  personalDoc_err: "",
  companyDoc: "",
  companyDoc_err: "",
  reUploadDoc: "",
  reUploadDoc_err: "",
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case CERTICATE_DETAIL_ERR:
      return {
        ...state,
        getCertificate_err: payload,
      };
    case CERTICATE_DETAIL:
      return {
        ...state,
        getCertificate: payload,
      };
    case COMPANY_DETAIL_ERR:
      return {
        ...state,
        getCompny_err: payload,
      };
    case COMPANY_DETAIL:
      return {
        ...state,
        getCompny: payload,
      };
    case UPLOAD_DOCUMENT_ERR:
      return {
        ...state,
        upload_err: payload,
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        upload_succ: payload,
      };
    case POST_ONBOARDING_STORE_ERR:
      return {
        ...state,
        onboardingStore_err: payload,
      };
    case POST_ONBOARDING_STORE:
      return {
        ...state,
        onboardingStore: payload,
      };
    case POST_SHAREHOLDER_STORE_ERR:
      return {
        ...state,
        shareholderStore_err: payload,
      };
    case POST_SHAREHOLDER_STORE:
      return {
        ...state,
        shareholderStore: payload,
      };
    case REGISTER_API_ERR:
      return {
        ...state,
        register_err: payload,
      };
    case REGISTER_API:
      return {
        ...state,
        register_succ: payload,
      };
    case SIGN_IN_API_ERR:
      return {
        ...state,
        sign_in_err: payload,
      };
    case SIGN_IN_API:
      return {
        ...state,
        sign_in_succ: payload,
      };
    case DASHBOARD_DETAIL_ERR:
      return {
        ...state,
        dashboard_err: payload,
      };
    case DASHBOARD_DETAIL:
      return {
        ...state,
        dashboard: payload,
      };
    case DASHBOARD_COMPANIES_DETAIL_ERR:
      return {
        ...state,
        dashboard_companies_err: payload,
      };
    case DASHBOARD_COMPANIES_DETAIL:
      return {
        ...state,
        dashboard_companies: payload,
      };
    case DASHBOARD_COMPANY_DOC_ERR:
      return {
        ...state,
        dashboard_company_doc_err: payload,
      };
    case DASHBOARD_COMPANY_DOC:
      return {
        ...state,
        dashboard_company_doc: payload,
      };
    case FORGET_PASSWORD_SEND_ERR:
      return {
        ...state,
        forget_password_send_err: payload,
      };
    case FORGET_PASSWORD_SEND:
      return {
        ...state,
        forget_password_send: payload,
      };
    case OTP_VERIFICATION_ERR:
      return {
        ...state,
        otp_verification_err: payload,
      };
    case OTP_VERIFICATION:
      return {
        ...state,
        otp_verification: payload,
      };

    case RESET_PASSWORD_ERR:
      return {
        ...state,
        reset_password_err: payload,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        reset_password: payload,
      };

    case VERIFICATION_DOC_ERR:
      return {
        ...state,
        verify_doc_err: payload,
      };
    case VERIFICATION_DOC:
      return {
        ...state,
        verify_doc: payload,
      };
    case PERSONAL_PROFILE_ERR:
      return {
        ...state,
        personalProfile_err: payload,
      };
    case PERSONAL_PROFILE:
      return {
        ...state,
        personalProfile: payload,
      };
    case SUPPORTING_DOC_ERR:
      return {
        ...state,
        supportingDoc_err: payload,
      };
    case SUPPORTING_DOC:
      return {
        ...state,
        supportingDoc: payload,
      };
    case DEGREE_CERTIFICATE_ERR:
      return {
        ...state,
        degreeCertificate_err: payload,
      };
    case DEGREE_CERTIFICATE:
      return {
        ...state,
        degreeCertificate: payload,
      };
    case SMART_CONTRACT_ERR:
      return {
        ...state,
        smartContract_err: payload,
      };
    case SMART_CONTRACT:
      return {
        ...state,
        smartContract: payload,
      };
    case PERSONAL_DOC_ERR:
      return {
        ...state,
        personalDoc_err: payload,
      };
    case PERSONAL_DOC:
      return {
        ...state,
        personalDoc: payload,
      };
    case COMPANY_DOC_ERR:
      return {
        ...state,
        companyDoc_err: payload,
      };
    case COMPANY_DOC:
      return {
        ...state,
        companyDoc: payload,
      };
    case RE_UPLOAD_ERR:
      return {
        ...state,
        reUploadDoc_err: payload,
      };
    case RE_UPLOAD:
      return {
        ...state,
        reUploadDoc: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
