import { combineReducers } from 'redux';
import login from './Login/reducer';
import user from './User/reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key:'root',
    storage,
    whitelist:['user']
}

const authPersist_user_Config={key:'user', storage: sessionStorage}


const rootReducer = combineReducers({

    user:persistReducer(authPersist_user_Config,user),
});
export default persistReducer(persistConfig,rootReducer);

// export default combineReducers({
//     login,
//     admin
// });