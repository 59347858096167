import React from "react";
import { ImCross } from "react-icons/im";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import building from "../../../Assets/Group 11.svg";
import pdf from "../../../Assets/Group 14.svg";
import one from "../../../Assets/Group 3.svg";
import two from "../../../Assets/Group 4.svg";
import three from "../../../Assets/Group 5 (1).svg";
import load from "../../../Assets/load";
import LegalDocumentSidebar from "../../../Component/LegalDocumentSideBar";
import SuccessModel from "../../../Component/SuccessModal";
import { UploadDocumentApi } from "../../../store/User/action";

class LegalDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfDocuments: [],
      count: 0,
      errors: "",
      loadState: false,
      addState: false,
      documentPresent: {},
    };
  }

  componentDidMount() {
    // if(localStorage.getItem('username')===null){
    //     this.props.history.push('/');
    // }
    console.log(this.props.history.location.state);
    this.setState({
      documentPresent: this.props.history.location.state,
    });
  }

  componentDidUpdate(prev) {
    if (prev.fail_documentUpload !== this.props.fail_documentUpload) {
      console.log(this.props.fail_documentUpload);
      toast.error(this.props?.fail_documentUpload?.response?.data?.message);
      this.setState({
        loadState: false,
      });
      if (
        this.props.fail_documentUpload?.response?.data?.error === "jwt expired"
      ) {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
    }

    if (prev.succ_documentUpload !== this.props.succ_documentUpload) {
      console.log(this.props.succ_documentUpload);
      this.setState({
        loadState: false,
        addState: !this.state.addState,
      });
    }
  }

  handlePassport = (e) => {
    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handlePassport = (e) => {
    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    if (document.getElementById(`upload-${name}`)) {
      document.getElementById(`upload-${name}`).value = "";
    }
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    if (this.state.file3 === undefined || this.state.file3 === "") {
      formIsValid = false;
      errors.file3 = "This is a required field";
    }

    if (this.state.file1 === undefined || this.state.file1 === "") {
      formIsValid = false;
      errors.file1 = "This is a required field";
    }
    if (this.state.file2 === undefined || this.state.file2 === "") {
      formIsValid = false;
      errors.file2 = "This is a required field";
    }
    if (this.state.file4 === undefined || this.state.file4 === "") {
      formIsValid = false;
      errors.file4 = "This is a required field";
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = () => {
    // if(this.handleValidation()){
    this.setState({
      loadState: true,
    });
    var min = 1;
    var max = 1000;
    var rand = Math.round(min + Math.random() * (max - min));

    console.log(rand);
    localStorage.setItem("random", rand);

    const formData = new FormData();
    formData.append("Incorporation", this.state.file1);
    formData.append("MoaAndAoa", this.state.file2);
    formData.append("Incumberency", this.state.file3);
    formData.append("Licence_CR", this.state.file4);
    this.state.noOfDocuments.map((data) => {
      formData.append(this.state[data], this.state[data + " file"]);
    });

    let companyId;
    if (
      localStorage.getItem("companyId") &&
      localStorage.getItem("companyId") !== undefined
    ) {
      companyId = localStorage.getItem("companyId");
    }
    this.props.UploadDocumentApi({
      body: formData,
      companyId: companyId || this.props.history.location.state.companyId,
    });
    // }
  };

  handleOk = () => {
    this.setState({
      addState: !this.state.addState,
    });
    this.props.history.push("/user/Dashboard");
  };

  handleAddDocuments = () => {
    this.setState({
      count: this.state.count + 1,
      noOfDocuments: [
        ...this.state.noOfDocuments,
        "Document " + (this.state.count + 1),
      ],
    });
  };

  removeDocument = (data) => {
    this.setState({
      count: this.state.count - 1,
      noOfDocuments: this.state.noOfDocuments.filter((item) => item !== data),
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="Onboard-page">
        {this.state.addState === true && (
          <SuccessModel handleOk={this.handleOk} />
        )}
        <ToastContainer />
        <div className="lg-page-banner">
          <LegalDocumentSidebar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="row first-row" style={{ display: "flex" }}>
            <label>Blockchain Documents</label>
          </div>
          <div className="row">
            {/* <p style={{marginLeft:'1.5%'}}>Complete the Onboarding Process , kindly read the user manual for any assistance.</p> */}
          </div>
          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Company Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}>Supporting Info</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p style={{ color: "#5197cb" }}> Blockchain Documents</p>
            </div>
          </div>
          <div className="row third-row">
            <div className="col-md-6">
              <img src={building} />
              <p>
                Register Document in to Blockchain <br />
                <span>
                  Upload documents to be registered in blockchain and complete
                  the onboarding(Kindly scan both sides of document*)
                </span>
              </p>
            </div>
            <div className="col-md-6 add-user">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
                onClick={this.handleAddDocuments}
              >
                Add Document +
              </div>
            </div>
          </div>
          <div className="row fourth-row" style={{ display: "flex" }}>
            <p>Corporate/Legal Document</p>
          </div>
          <div className="row sixth-row">
            <div
              className="col-md-4"
              style={{
                display: `${
                  this.state.documentPresent?.incorporation ? "none" : ""
                }`,
              }}
            >
              <p>
                Copy of Certificate of Incorporation{" "}
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF.</p>
                <input
                  type="file"
                  id="upload-file1"
                  name="file1"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file1">Upload</label>
              </div>
              {this.state.errors.file1 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file1}
                </p>
              )}
              {this.state.file1 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file1.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file1")}
                  />
                </div>
              )}
            </div>
            <div
              className="col-md-4"
              style={{
                display: `${
                  this.state.documentPresent?.moaAndAoa ? "none" : ""
                }`,
              }}
            >
              <p>
                Copy of MOA & AOA{" "}
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file2"
                  name="file2"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file2">Upload</label>
              </div>
              {this.state.errors.file2 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file2}
                </p>
              )}
              {this.state.file2 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file2.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file2")}
                  />
                </div>
              )}
            </div>
            <div
              className="col-md-4"
              style={{
                display: `${
                  this.state.documentPresent?.incumberency ? "none" : ""
                }`,
              }}
            >
              <p>
                Certificate of Incumbency{" "}
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file3"
                  name="file3"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file3">Upload</label>
              </div>
              {this.state.errors.file3 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file3}
                </p>
              )}
              {this.state.file3 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file3.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file3")}
                  />
                </div>
              )}
            </div>
            <div
              className="col-md-4"
              style={{
                display: `${
                  this.state.documentPresent?.Licence_CR ? "none" : ""
                }`,
              }}
            >
              <p>
                Licence/CR
                {/* <span style={{ color: "red", marginLeft: "10px" }}>*</span> */}
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file4"
                  name="file4"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file4">Upload</label>
              </div>
              {this.state.errors.file4 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file4}
                </p>
              )}
              {this.state.file4 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file4.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file4")}
                  />
                </div>
              )}
            </div>

            {this.state.noOfDocuments.map((data) => (
              <div className="col-md-4">
                <p className="d-flex justify-content-between">
                  <span>
                    {data}{" "}
                    <span style={{ color: "red", marginLeft: "10px" }}>*</span>
                  </span>
                  <span
                    className=""
                    style={{ cursor: "pointer" }}
                    onClick={() => this.removeDocument(data)}
                  >
                    X
                  </span>
                </p>
                <div className="d-flex flex-column">
                  <p>Valid Format PDF</p>
                  <div className="d-flex">
                    <div className="w-75">
                      <input
                        type="text"
                        value={this.state[data]}
                        name={data}
                        onChange={this.handleChange}
                        placeholder="Enter the name of document"
                      />
                    </div>
                    <div className="w-25">
                      <label for={data + " file"}>Upload</label>
                      <input
                        type="file"
                        id={data + " file"}
                        name={data + " file"}
                        accept=".pdf"
                        hidden
                        onChange={this.handlePassport}
                      />
                    </div>
                  </div>
                </div>
                {this.state.errors[data] !== undefined && (
                  <p
                    style={{ color: "red", fontSize: "13px", marginTop: "0px" }}
                  >
                    {this.state.errors[data]}
                  </p>
                )}
                {this.state.errors[data + " file"] !== undefined && (
                  <p
                    style={{ color: "red", fontSize: "13px", marginTop: "0px" }}
                  >
                    {this.state.errors[data + " file"]}
                  </p>
                )}
                {this.state[data + " file"] !== undefined && (
                  <div className="paradiv">
                    <img src={pdf} />
                    <a id="para">{this.state[data + " file"].name}</a>{" "}
                    <ImCross
                      style={{
                        fontSize: "10px",
                        marginTop: "3%",
                        marginLeft: "10px",
                      }}
                      onClick={() => this.cleanFile(data + " file")}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    succ_documentUpload: state.user.upload_succ,
    fail_documentUpload: state.user.upload_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      UploadDocumentApi,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalDocuments);
