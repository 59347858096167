
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import banner1 from '../Assets/Business ethics-pana (1).svg';
import minilogo from '../Assets/Path 1350.svg';
import { postSignUpApi } from '../store/User/action';

import bgImage from '../Assets/shared image.jpg'
import dubailogo from '../Assets/Blockhain_reg_new.png'

class SignUp extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            search: '' ,
            password:'',
            username:'',
            email:'',
            errors:'',
            signIn:true

        };
    }

    handleRedirect=()=>{
        this.props.history.push("/");
    }
    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }

    handleSignUp=()=>{
        const body={
            username:this.state.username,
            email: this.state.email,
            password: this.state.password,
            role: "representative" 
        }

        if(this.state.username===''|| this.state.password===''){
            toast.error('Username/Password cannot be empty');
            return;
        }

        if(this.state.email===''){
            toast.error("Email cannot be empty");
            return;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
            toast.error("Invalid email address");
            return;
        }
        if (!/^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[a-z]).{8,}$/i.test(this.state.password)) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character");
            return;
        }

        this.props.postSignUpApi(body)
    }

    componentDidUpdate(prev){
        if(prev.succ_reg !== this.props.succ_reg){
            toast.success("Sign-up successful")
            this.props.history.push('/')
        }
        if(prev.fail_reg !== this.props.fail_reg){
            console.log(this.props.fail_reg)
            toast.error(this.props.fail_reg.response.data.message||this.props.fail_reg.response.data.Error||"Sign up failed")
        }
    }

    render(){
        return(
            <div className='login-page'>
                <ToastContainer />
                {/* <div className='lg-page-banner'>
                   <div className='banner'>
                   <label style={{color:'white'}}>Decentralized and Uncompromised trust <br/> BlockChain Registry</label>
                        <p style={{color:'white'}}>Kindly complete the Onboarding Process</p>
                       
                   </div>
                </div>
                <div className='lg-page-form py-3'>
                    <div className='lg-pg-form-box'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='form-title'>Sign Up</label><br/>
                                <label className='form-subtitle'>Enter your details to create a new account</label>
                            </div>
                        </div>
                        <div className="row form-field">
                            <div className='form-input'>
                                <label>Enter your Username</label>
                                <input type="text" name="username" value={this.state.username} placeholder="Username " onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                <label>Enter your Email ID</label>
                                <input type="text" name="email" value={this.state.email} placeholder="Username or E-Mail ID" onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                <label>Enter your Password</label>
                                <input type="password" name="password" value={this.state.password} placeholder="Enter your password" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className='row form-submit1'>
                            <button onClick={this.handleSignUp}>SignUp</button>
                        </div>
                        <div className='row create_accnt'>
                            <center>
                                <a onClick={() => this.handleRedirect()}>Already have an account ? <span>Sign In</span></a>
                            </center>
                        </div>
                    </div>
                </div> */}
                  <div className='new-lg-page-form'>
                    <div className='bg-image'>
                        <img src={bgImage}/>
                    </div>
                    <div className='top-head'>
                        <div style={{fontSize:'30px',fontWeight:'bold'}}>Decentralized and uncompromised trust - Blockchain Registry </div> 
                        <div style={{fontSize:'18px'}}>Complete the Onboarding Process.</div>
                    </div>

                    <div className='lg-pg-form-box'>
                        <img src={dubailogo} style={{width:'20%'}}/>
                       
                        <div className="row form-field">
                            <div className='form-input'>
                                {/* <label>Enter your Username or Email ID</label> */}
                                <input type="text" name="username" value={this.state.username} placeholder="Enter your Username " onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                {/* <label>Enter your Email ID</label> */}
                                <input type="text" name="email" value={this.state.email} placeholder="Enter your E-Mail ID" onChange={this.handleChange}/>
                            </div>
                            <div className='form-input'>
                                {/* <label>Enter your Password</label> */}
                                <input type="password" name="password" value={this.state.password} placeholder="Enter your password" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className='row form-submit1'>
                            <button onClick={this.handleSignUp}>SignUp</button>
                        </div>
                        <div className='row create_accnt'>
                            <center>
                                <a onClick={() => this.handleRedirect()}>Already have an account ? <span>Sign In</span></a>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        succ_reg: state.user.register_succ,
        fail_reg: state.user.register_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        postSignUpApi,
      },
      dispatch,
    )
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(SignUp)






